.ui.segment.date-selection{
  width: 100% ;
  margin: 40px;
  max-width: 700px;

  .date-selection__search-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
}