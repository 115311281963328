.ui.inverted.button.primary {
  border: 1px solid $primary !important;
  box-shadow: none !important;
  transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.button.primary {
  background-color: $primary !important;
}

.button.primary:focus {
  background-color: transparent !important;
}

.button.primary:hover {
  background-color: $primary !important;
  color: $white !important;
}

.ui.icon.button {
  margin-top: 5px;
}

.ui.basic.button {
  color: $primary !important;
  border: $primary !important;
}

.ui.basic.button:focus {
  color: $primary !important;
  border: 1px solid $primary !important;
}

.ui.basic.button:hover {
  background-color: $primary !important;
  color: white !important;
  border: $primary !important;
}

.ui.primary.labeled.icon.button {
  background-color: lighten($gray-200, 10);
  color: $gray-500 !important;

  &:hover {
    color: $white !important;
  }
}

button#get-started {
  font-weight: lighter;
  font-size: large;

  @include respond-below(sm) {
    font-size: medium;
  }
}