.ui.customize-table.table {
  padding: 5em !important;

  @include respond-above(md) {
    padding: 5em 10em !important;
    font-size: medium;
  }
}

.ui.celled.striped.table{
  padding: 0 !important;
  width: 90%;
  margin: 0 auto;
}

#repository-table{
  td{
    padding: 0;
  }
  tr{
    cursor: pointer;
  }
}