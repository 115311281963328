.app-sidebar {
  > .ui.visible.sidebar {
    @include respond-below(sm) {
      visibility: hidden;
      width: 0;
      transition: 0.5s;
    }
  }

  &.visibility {
    > .ui.visible.sidebar {
      @include respond-below(sm) {
        visibility: visible;
        width: 15rem;
      }
    }
  }
}

.ui.inverted.menu {
  top: auto !important;
  background-color: $primary !important;

  .item {
    overflow: hidden;
    white-space: nowrap;
  }
}

.ui.menu {
  border: 0;
  border-radius: 0;

  .large {
    height: $navbar-height;
    width: 100%;
    position: fixed;
    top: 0;
  }

  > .sidebar.item {
    display: none;
    transition: background-color 0.1s;

    &:hover {
      background-color: rgba($black, 0.03);
    }

    @include respond-below(sm) {
      display: inline-block;
    }
  }
}

.sidebar.icon {
  display: none;
  justify-content: center;
  padding: auto;

  @include respond-below(sm) {
    display: inline-block;
  }
}

.dropdown-menu .menu {
  position: absolute;
  transition: all 0.25s ease;
}

.dropdown-menu:hover .menu {
  display: inherit !important;
}

