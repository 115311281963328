#app-header {
  z-index: 1000;
  top: 0;
  width: 100%;
  position: fixed;
}

#profile-info-text {
  color: lighten($black, 50);
  margin-top: 60px;
  font-weight: normal;
  margin-bottom: 50px;

  @include respond-below(sm) {
    font-size: large;
  }
}

.section__header{
  margin: 20px;
}