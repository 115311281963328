.input {
  margin: 7px !important;
}

.input:focus {
  border-color: $primary !important;
}

.ui.inverted.form {
  width: 55%;
  display: block;
  margin: 0 auto;

  @include respond-below(md) {
    width: 60%;
  }

  @include respond-below(sm) {
    width: 80%;
  }
}

.field.in-comment.in-center {
  display: flex;
  justify-content: center;
}

.ui.horizontal.divider ~ .ui.inverted.form {
  margin: 50px auto;
}

.comment {
  position: relative;
  margin: 5px 15px;

  > .avatar {
    position: relative;
    display: inline-block;
  }

  > .content {
    > .description {
      font-size: 110%;
      margin: 10px auto auto auto;
    }
  }
}

.comment-header {
  display: inline-block;

  > .header {
    position: absolute;
    display: inline-block;
    font-weight: bold;
    font-size: 110%;
    margin-left: 10px;
  }

  > .meta {
    position: absolute;
    display: inline-block;
    top: 25%;
    margin-left: 7px;
  }
}

.field {
  > .error.visible {
    margin-top: 20px;
    margin-left: 7px;
    padding: 10px;
    font-size: small;
    color: $dark-pink;
    background-color: $baby-pink;
    border: 1px solid $light-pink;
    border-radius: 4px;
  }
}

.ui.calendar.date-picker{
  .ui.input{
    margin: 0 !important;
  }
}