.fas.fa-comment-alt {
  color: $primary;
  display: inline-block;
  font-size: 500%;
}

.close-container {
  margin: 20px;
  width: 40px;
  float: right;
  height: 40px;
  cursor: pointer;
}

.left-right {
  height: 3px;
  width: 40px;
  position: absolute;
  margin-top: 24px;
  background-color: $primary;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all .3s ease-in;
}

.right-left {
  height: 3px;
  width: 40px;
  position: absolute;
  margin-top: 24px;
  background-color: $primary;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all .3s ease-in;
}

.close-container{
  label {
    color: $primary;
    font-size: .6em;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all .3s ease-in;
    opacity: 0;
  }
}
.close {
  margin: 50px 0 0 5px;
  position: absolute;
}

.close-container:hover {
  .left-right {
    transform: rotate(-45deg);
    background-color: darken($primary, 50);
  }

  .right-left {
    transform: rotate(45deg);
    background-color: darken($primary, 50);
  }

  label {
    opacity: 1;
  }
}

#profile-image {
  text-align: center;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  max-width: 100%;

  @include respond-below(md) {
    margin-left: 30px;
    margin-right: 30px;
  }

  img {
    max-width: 30%;
  }
}

.horizontal-nav-scroll {
  opacity: 0.5;
  position: relative;
  margin: auto;
  transition: opacity 0.25s;

  @include respond-below(md) {
    display: none;
  }

  .icon {
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &:first-of-type {
    padding-right: 5px;
  }

  &:last-of-type {
    padding-left: 5px;
  }
}