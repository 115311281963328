.ui.card {
  flex: auto !important;
  width: 300px;
  min-height: 115px;
  height: 100%;
  box-sizing: border-box;

  > .ui.image {
    height: 150px;
    width: 100%;

    > img {
      height: 150px;
    }
  }

  &:hover {
    border-bottom: 1px solid $primary;
  }
}

.ui.cards {
  padding: 1em;
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: repeat(auto-fill, max(300px));
  margin: auto;
  justify-content: center;

  @include respond-below(xs) {
    grid-template-columns: auto;
  }
}

.ui.stackable.cards {
  @include respond-below(sm) {
    display: grid !important;
    grid-gap: 1em;
    margin: 0 auto;
  }
}
