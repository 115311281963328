.ui.grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 !important;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

.ui.grid.login {
  min-height: 100vh;
  background-color: $white;
}

.ui.column.grid > [class*='five wide'].column {
  padding: 0 !important;

  @include respond-below(lg) {
    width: 40% !important;
  }

  @include respond-below(sm) {
    width: 100% !important;
  }
}

