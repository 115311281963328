* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  min-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

html {
  height: 100% !important;
}

.ui.dashboard {
  background-color: $white;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.ui.dashboard.login {
  background-image: url('../../assets/road-home.png');
  background-size: cover;
  background-position: center;
}

.ui.repository-login {
  height: calc(100vh - 100px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: background-color 0.5s;

  &.visible {
    @include respond-below(sm) {
      z-index: 10;
      background-color: rgba($black, 0.5);
    }
  }
}

