@media only screen and (max-width: 767px) {
  .ui.tiny.modal {
	width: 95%;
	margin: 0em 0em 0em -47.5%;
  }
}

@media only screen and (min-width: 768px) {
  .ui.tiny.modal {
	width: 50%;
	margin: 0em 0em 0em -25%;
  }
}

@media only screen and (min-width: 992px) {
  .ui.tiny.modal {
	width: 200px;
	margin: 0em 0em 0em -100px;
  }
}

@media only screen and (min-width: 1200px) {
  .ui.tiny.modal {
	width: 300px;
	margin: 0em 0em 0em -150px;
  }
}

@media only screen and (min-width: 1920px) {
  .ui.tiny.modal {
	width: 400px;
	margin: 0em 0em 0em -200px;
  }
}